import { Button, Flex, InputRightAddon } from '@chakra-ui/react'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { TextField } from '../../fields'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { GetAllUsersParams } from '../../../ts/interfaces/request'

interface IUsersFilterProps {
  isLoading: boolean
  retrieveData: (params: Partial<GetAllUsersParams>) => Promise<void>
  setPage: (value: number) => void
  setFilterBy: (value: string) => void
  filterBy: string
}

export interface IUsersFilterRef {
  handleClearSearchParams: () => void
}

const UsersFilterForwards = React.forwardRef(({ retrieveData, isLoading, setPage, setFilterBy, filterBy }: IUsersFilterProps, ref) => {
  const handleSearchUsers = async () => {
    setPage(1)
    await retrieveData({ filter_by: filterBy, setStateResult: false })
  }

  React.useEffect(() => {
    if (filterBy === '') handleSearchUsers()
  }, [filterBy])

  return (
    <Flex
      alignItems='center'
      justifyContent={{
        base: 'center',
        md: 'start'
      }}
      columnGap='1rem'
      flexDir='row'
      flexWrap='wrap'
      width='100%'
    >
      <TextField
        isDisabled={isLoading}
        label='Filtrar por Nombre, Email o Número de teléfono'
        rightChildren={
          <InputRightAddon
            onClick={handleSearchUsers}
            p={0}
          >
            <Button>
              <FaMagnifyingGlass />
            </Button>
          </InputRightAddon>
        }
        onKeyDownCapture={(e) => {
          if (e.code === 'Enter') handleSearchUsers()
        }}
        placeholder='Buscar'
        value={filterBy}
        onChange={(e) => setFilterBy(e.target.value)}
      />
    </Flex>
  )
})

const UsersFilter = React.memo(UsersFilterForwards)

export { UsersFilter }
