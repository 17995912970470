import React from 'react'
import { Modal, useDisclosure, Radio, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Heading, RadioGroup, Stack, ModalCloseButton, Flex, useToast, SimpleGrid } from '@chakra-ui/react'
import { ArrowDownIcon } from '@chakra-ui/icons'
import { DateRangePicker, DateValue } from '../fields/Date/DateRangePicker'
import { SelectField } from '../fields'
import { DownloadExpensesBulkType, ExpensesFilterByOptions, ExpensesFilterBy } from '../../ts/interfaces/expenses'
import { ExpensesService } from '../../services/Expenses'
import moment from 'moment'
import { Button } from '../fragments/Button'
import { Text } from '../fragments/Text'
import { colors } from '../../utils/colors'
import { getTaxInformationName, ReceiptStatus, ReceiptStatusOptions } from '../../ts/interfaces/invoices'
import { useAppSelector } from '../../store/hooks'
import { InvoicesService } from '../../services/Invoices'
import _ from 'lodash'

const DownloadExpensesModal = React.memo(() => {
  const [selectedDates, setSelectedDates] = React.useState<DateValue>([null, null])
  const [exportDataType, setExportDataType] = React.useState<DownloadExpensesBulkType | undefined>()
  const [userId, setUserId] = React.useState<number>(0)
  const [status, setStatus] = React.useState<ReceiptStatus | null | undefined>(null)
  const [taxInformationId, setTaxInformationId] = React.useState<string>('')
  const [filterBy, setFilterBy] = React.useState<ExpensesFilterBy>(ExpensesFilterBy.DATE)

  const [predefinedDate, setPredefinedDate] = React.useState<string | undefined>('1')

  const taxInformationList = useAppSelector((state) => state.invoices.taxInformation)
  const userList = useAppSelector((state) => state.organization.users)
  const modalDisclosure = useDisclosure()
  const loadingDisclosure = useDisclosure()
  const toast = useToast()

  React.useEffect(() => {
    InvoicesService().getTaxInformation()
  }, [])

  React.useEffect(() => {
    if (!modalDisclosure.isOpen) {
      setPredefinedDate('1')
      setSelectedDates([null, null])
      setExportDataType(undefined)
    }
  }, [modalDisclosure.isOpen])

  const isDisabledDownload = React.useMemo(() => !exportDataType || (predefinedDate === '5' && !selectedDates[0] && !selectedDates[1]), [selectedDates, exportDataType, predefinedDate])

  const handleDownload = React.useCallback(async () => {
    let start_date = moment().startOf('day').toDate()
    let end_date = moment().endOf('day').toDate()

    if (predefinedDate === '5') {
      start_date = selectedDates[0] as Date
      end_date = selectedDates[1] as Date
    } else if (predefinedDate === '4') {
      start_date = moment().subtract(1, 'month').startOf('month').toDate()
      end_date = moment().subtract(1, 'month').endOf('month').toDate()
    } else if (predefinedDate === '3') {
      start_date = moment().startOf('month').toDate()
      end_date = moment().endOf('month').toDate()
    } else if (predefinedDate === '2') {
      start_date = moment().subtract(7, 'days').startOf('day').toDate()
      end_date = moment().endOf('day').toDate()
    }

    try {
      loadingDisclosure.onOpen()
      await ExpensesService({
        useLoader: false,
        handleResponseError: (error) => {
          const status = error.response?.status || error.status || 0
          if ([404].includes(status)) {
            toast({
              title: 'No se encontraron resultados',
              description: 'Por favor, intente con otros criterios de búsqueda.',
              status: 'warning',
              isClosable: true,
              duration: 5000
            })
          } else {
            toast({
              title: 'Hubo un problema',
              status: 'error',
              isClosable: true,
              duration: 8000
            })
          }
        }
      }).downloadExpensesData(exportDataType as DownloadExpensesBulkType, { end_date, start_date, user_id: userId, tax_information_id: taxInformationId, filter_by: filterBy, status: status })
      loadingDisclosure.onClose()
    } catch (error) {
      loadingDisclosure.onClose()
    }
  }, [exportDataType, selectedDates, predefinedDate, filterBy, taxInformationId, userId, status])

  const taxInformationOptions = [
    { value: '', label: 'Todas' },
    ...taxInformationList.map((taxInformation) => ({
      label: `${getTaxInformationName(taxInformation)} - ${taxInformation.tax_id}`,
      value: taxInformation.tax_information_id
    }))
  ]

  const usersOptions = [
    { value: 0, label: 'Todos' },
    ...userList.map((user) => ({
      value: user.id,
      label: user.name || user.email
    }))
  ]

  return (
    <>
      <Button
        onClick={modalDisclosure.onOpen}
        rightIcon={<ArrowDownIcon />}
        id='DownloadExpensesButton'
      >
        Descargar Gastos
      </Button>

      <Modal
        trapFocus={false}
        closeOnOverlayClick={!loadingDisclosure.isOpen}
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        isCentered
        size={{
          base: '100%',
          md: '80%',
          xl: '50%'
        }}
      >
        <ModalOverlay />

        <ModalContent
          maxW={{
            base: '100%',
            md: '80%',
            xl: '50%'
          }}
        >
          <ModalHeader textAlign='center'>
            <Heading
              textAlign='start'
              fontSize='1.5rem'
              as={'h2'}
              mb='1rem'
            >
              Descargar Gastos
            </Heading>
            <Text
              textAlign='start'
              fontSize='1rem'
              fontWeight={400}
            >
              Toda la información se descargará usando la fecha de los recibos.
            </Text>
            <ModalCloseButton isDisabled={loadingDisclosure.isOpen} />
          </ModalHeader>

          <ModalBody>
            <SelectField
              isRequired
              label='Tipo de descarga'
              id='DownloadExpensesBulkTypeSelect'
              customWidth={{
                base: '100%',
                md: '80%'
              }}
              placeholder='Selecciona el tipo de descarga'
              isClearable={false}
              name='exportDataType'
              value={exportDataType}
              onChange={(_, value) => {
                setExportDataType(value as DownloadExpensesBulkType)
              }}
              isDisabled={loadingDisclosure.isOpen}
              options={[
                {
                  label: 'Excel',
                  value: DownloadExpensesBulkType.CSV
                },
                {
                  label: 'Zip con PDF y XML',
                  value: DownloadExpensesBulkType.ZIP_PDF_XML
                }
              ]}
            />
            <RadioGroup
              mb='rem'
              onChange={setPredefinedDate}
              value={predefinedDate}
            >
              <Stack direction='column'>
                <Radio
                  isDisabled={loadingDisclosure.isOpen}
                  value='1'
                >
                  Hoy
                </Radio>
                <Radio
                  isDisabled={loadingDisclosure.isOpen}
                  value='2'
                >
                  Últimos siete días
                </Radio>
                <Radio
                  isDisabled={loadingDisclosure.isOpen}
                  value='3'
                >
                  Mes actual
                </Radio>
                <Radio
                  isDisabled={loadingDisclosure.isOpen}
                  value='4'
                >
                  Mes pasado
                </Radio>
                <Radio
                  isDisabled={loadingDisclosure.isOpen}
                  value='5'
                >
                  Personalizado
                </Radio>
              </Stack>
            </RadioGroup>

            <SimpleGrid
              columns={{
                base: 1,
                md: 2,
                lg: 2
              }}
            >
              <DateRangePicker
                label='Rango de fechas'
                value={selectedDates}
                onChange={setSelectedDates}
                isDisabled={predefinedDate !== '5' || loadingDisclosure.isOpen}
              />

              <SelectField
                label='Filtrar por fecha de:'
                isSearchable={false}
                isClearable={false}
                options={ExpensesFilterByOptions}
                value={filterBy}
                onChange={(_, value) => {
                  setFilterBy(() => value as ExpensesFilterBy)
                }}
                isDisabled={loadingDisclosure.isOpen}
              />

              <SelectField
                label='Filtrar por razón social'
                isClearable={false}
                isSearchable={false}
                value={taxInformationId}
                options={taxInformationOptions}
                onChange={(_, value) => {
                  setTaxInformationId(() => value as string)
                }}
                isDisabled={loadingDisclosure.isOpen}
              />
              <SelectField
                label='Filtrar por usuario'
                isClearable={false}
                isSearchable={false}
                value={userId}
                options={usersOptions}
                onChange={(_, value) => {
                  setUserId(() => value as number)
                }}
                isDisabled={loadingDisclosure.isOpen}
              />
              <SelectField
                isSearchable={false}
                isClearable={true}
                options={ReceiptStatusOptions}
                value={filterBy}
                onChange={(_, value) => {
                  setStatus(value as ReceiptStatus)
                }}
                label='Filtrar por estatus:'
              />
            </SimpleGrid>
          </ModalBody>

          <ModalFooter
            justifyContent='center'
            pb='2rem'
          >
            <Flex
              direction='column'
              columnGap='1rem'
              rowGap='1rem'
              width='100%'
            >
              <Flex justifyContent='center'>
                <Button
                  id='DownloadExpensesSubmitButton'
                  onClick={handleDownload}
                  isLoading={loadingDisclosure.isOpen}
                  isDisabled={isDisabledDownload || loadingDisclosure.isOpen}
                >
                  Descargar
                </Button>
              </Flex>

              <Text
                fontSize='.8rem'
                textColor={colors.tertiary[1]}
                fontWeight={600}
                display={loadingDisclosure.isOpen ? 'initial' : 'none'}
              >
                *Por favor espera unos segundos mientras se prepara el archivo, este proceso puede tardar un par de minutos.*
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})

export { DownloadExpensesModal }
