import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ExpenseStatus } from '../../ts/interfaces/expenses'

interface State {
  expenses: ExpenseStatus[]
  total: number
}

export const initialState: State = {
  expenses: [],
  total: 0
}

export const expenses = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setExpensesList: (state, action: PayloadAction<State>) => {
      return { ...state, expenses: [...action.payload.expenses], total: action.payload.total }
    },
    clearState: () => {
      return initialState
    }
  }
})

export const { clearState, setExpensesList } = expenses.actions

export default expenses.reducer
