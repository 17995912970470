import * as React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Flex, Skeleton, chakra } from '@chakra-ui/react'
import { useReactTable, flexRender, getCoreRowModel, ColumnDef, PaginationState, getPaginationRowModel } from '@tanstack/react-table'
import { colors } from '../../utils/colors'
import { Paginator } from './Paginator'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { useAppSelector } from '../../store/hooks'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { SortType } from '../../ts/interfaces/hooks'

export type IDataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
  total: number
  page?: number
  page_size?: number
  setPage?: (value: number) => void
  setPageSize?: (value: number) => void
  isLoading?: boolean
  noDataDisclaimer?: JSX.Element | null
  sort_by?: string
  setSortBy?: (value: string) => void
  sort_type?: SortType
  setSortType?: (value: SortType) => void
}

export const PAGINATION_CONFIG = {
  initialConfig: {
    page: 1,
    pageIndex: 0, //initial page index
    pageSize: 5 //default page size
  },
  pages: [5, 10, 25, 50]
}

export function DataTable<Data extends object>({ data, total, page, page_size, setPage, setPageSize, columns, isLoading = false, noDataDisclaimer, sort_by, sort_type, setSortBy, setSortType }: IDataTableProps<Data>) {
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: PAGINATION_CONFIG.initialConfig.pageIndex, pageSize: PAGINATION_CONFIG.initialConfig.pageSize })
  const ui = useAppSelector((state) => state.ui)
  const pageCount = React.useMemo(() => Math.ceil((total || data.length) / pagination.pageSize), [total, data, pagination])

  React.useEffect(() => {
    if (page && page_size) {
      setPagination({
        pageIndex: 0, // force pageIndex to 0 because paginated data comes limited by default, this only updates pageSize
        pageSize: page_size
      })
    }
  }, [page, page_size, setPagination])

  const table = useReactTable({
    columns,
    data: data,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetAll: false,
    paginateExpandedRows: false,
    state: {
      pagination
    }
  })

  const boundingBoxRef = React.useRef(null)

  const tBody = (
    <Tbody
      alignContent='start'
      placeContent='start'
      justifyContent='start'
      rowGap='1rem'
    >
      {isLoading
        ? [...Array(pagination.pageSize).keys()].map((row, index) => (
            <Tr
              maxW='100vw'
              transition='.2s ease-in'
              _hover={{
                backgroundColor: colors.neutro[1]
              }}
              key={`${row}-${index}`}
            >
              {[...Array(columns.length).keys()].map((cell, index) => {
                return (
                  <Td
                    key={`${cell}-${index}`}
                    fontSize={{
                      base: '.7rem'
                    }}
                    overflow='hidden'
                    flexWrap='wrap'
                  >
                    <Skeleton height='25px'></Skeleton>
                  </Td>
                )
              })}
            </Tr>
          ))
        : table.getRowModel().rows.map((row) => (
            <Tr
              maxW='100vw'
              ref={boundingBoxRef}
              transition='.2s ease-in'
              _hover={{
                backgroundColor: colors.neutro[1]
              }}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => {
                const meta: any = cell.column.columnDef.meta
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    fontSize={{
                      base: '1rem'
                    }}
                    overflow='hidden'
                    flexWrap='wrap'
                  >
                    {isLoading ? <Skeleton height='25px'></Skeleton> : flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
            </Tr>
          ))}
    </Tbody>
  )

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      minHeight={
        !data.length && noDataDisclaimer
          ? 0
          : {
              base: '',
              md: '500px',
              lg: '600px'
            }
      }
      overflowX='auto'
      maxW='100%'
    >
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta: any = header.column.columnDef.meta
                return (
                  <Th
                    fontSize={{
                      base: '.7rem'
                    }}
                    _hover={{
                      backgroundColor: colors.neutro[1]
                    }}
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    padding='0.4rem'
                    cursor={setSortBy && setSortType && header.column.id !== 'actions' ? 'pointer' : 'initial'}
                    onClick={() => {
                      if (header.column.id === 'actions') return
                      if (setSortBy && setSortType) {
                        if (sort_by === header.column.id) {
                          setSortType(!sort_type ? SortType.DESC : sort_type === SortType.DESC ? SortType.ASC : SortType.DESC)
                        } else setSortType(SortType.DESC)
                        setSortBy(header.column.id)
                      }
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    <chakra.span pl='4'>{sort_by === header.column.id ? sort_type === SortType.DESC ? <TriangleDownIcon aria-label='sorted descending' /> : <TriangleUpIcon aria-label='sorted ascending' /> : null}</chakra.span>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        {tBody}
      </Table>
      {!ui.isFetching && !isLoading && !data.length && noDataDisclaimer ? noDataDisclaimer : null}
      <Paginator
        total={total}
        page={page}
        page_size={page_size}
        setPage={setPage}
        setPageSize={setPageSize}
        isDisabled={!data.length ? true : isLoading}
        pageCount={pageCount}
        setPagination={setPagination}
        pagination={pagination}
        table={table}
      />
    </Flex>
  )
}
