import React from 'react'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'
import { PAGINATION_CONFIG } from '../components/fragments/Table'

interface IUseUserUrlParamsFilterProps {
  initialFilterBy?: string
  initialPage?: number
  initialPageSize?: number
}

const getInitialFilterBy = (searchParams: URLSearchParams, initialState = ''): string => {
  const filter_by = searchParams.get('filter_by')
  if (filter_by !== null && filter_by !== '') return filter_by
  return initialState
}

const getInitialPage = (searchParams: URLSearchParams, initialValue: number): number => {
  const page = searchParams.get('page')
  if (page !== null && page !== '') return parseInt(page)
  return initialValue
}

const getInitialPageSize = (searchParams: URLSearchParams, initialValue: number): number => {
  const page_size = searchParams.get('page_size')
  if (page_size !== null && page_size !== '') return parseInt(page_size)
  return initialValue
}

const useUserUrlParamsFilter = ({ initialFilterBy = '', initialPage = PAGINATION_CONFIG.initialConfig.page, initialPageSize = PAGINATION_CONFIG.initialConfig.pageSize }: IUseUserUrlParamsFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [page, setPage] = React.useState<number>(getInitialPage(searchParams, initialPage))
  const [page_size, setPageSize] = React.useState<number>(getInitialPageSize(searchParams, initialPageSize))
  const [filterBy, setFilterBy] = React.useState<string>(getInitialFilterBy(searchParams, initialFilterBy))

  React.useEffect(() => {
    searchParams.set('page', page.toString())
    searchParams.set('page_size', page_size.toString())
    searchParams.set('filter_by', filterBy)
    setSearchParams(searchParams)
  }, [])

  return {
    filterBy,
    setFilterBy: (value: string) => {
      searchParams.set('filter_by', value.toString())
      setSearchParams(searchParams)
      setFilterBy(() => value)
    },
    page,
    page_size,
    setPage: (value: number) => {
      searchParams.set('page', value.toString())
      setSearchParams(searchParams)
      setPage(() => value)
    },
    setPageSize: (value: number) => {
      searchParams.set('page_size', value.toString())
      setSearchParams(searchParams)
      setPageSize(() => value)
    }
  }
}

export { useUserUrlParamsFilter }
