'use client'
import React from 'react'
import { PageLayout } from '../components/Layout/PageLayout'
import { Flex, Stack, useBoolean } from '@chakra-ui/react'
import { Title } from '../components/fragments/Title'
import moment from 'moment'
import { DownloadExpensesModal } from '../components/ui/DownloadExpensesModal'
import { ExpensesFilter } from '../components/ui/ExpensesFilter'
import { ExpensesService } from '../services/Expenses'
import { ExpensesFilterBy, ExpenseStatus } from '../ts/interfaces/expenses'
import { isValidDateRange } from '../utils/date'
import { useUrlParamFilter } from '../hooks/useDateUrlParamFilter'
import { ExpensesTable } from '../components/ui/tables/ExpensesTable'

const ReceiptsDashboardPage = () => {
  const [isLoading, toggleIsLoading] = useBoolean(false)
  const [data, setData] = React.useState<ExpenseStatus[]>([])
  const [total, setTotal] = React.useState<number>(0)

  const { dateValue, setDateValue, filterBy, setFilterBy, userId, setUserId, taxInformationId, setTaxInformationId, page, page_size, setPage, setPageSize, setSortBy, setSortType, sort_by, sort_type, status: filterStatus, setStatus } = useUrlParamFilter({ useFilterBy: false, initialFilterBy: ExpensesFilterBy.DATE, initialDateRange: [moment().startOf('month').toDate(), moment().toDate()], usePagination: true })

  const handleRetrieveData = async () => {
    try {
      if (isLoading) return

      if (isValidDateRange(dateValue)) {
        toggleIsLoading.on()
        const response = await ExpensesService({ useLoader: false }).getAllExpenses({
          start_date: dateValue[0],
          end_date: dateValue[1],
          user_id: userId,
          tax_information_id: taxInformationId,
          filter_by: filterBy,
          page: page,
          page_size: page_size,
          sort_by,
          sort_type,
          status: filterStatus
        })
        setTotal(() => response.total)
        setData(() => response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      toggleIsLoading.off()
    }
  }

  React.useEffect(() => {
    handleRetrieveData()
  }, [])

  return (
    <PageLayout>
      <Stack mb='1rem'>
        <Flex
          flexDir={{
            base: 'column',
            md: 'row'
          }}
          mb='1rem'
          alignItems='center'
          justifyContent={{
            base: '',
            md: 'space-between'
          }}
        >
          <Title>Gastos</Title>
          <DownloadExpensesModal />
        </Flex>

        <ExpensesFilter
          setStatus={setStatus}
          status={filterStatus}
          sort_by={sort_by}
          sort_type={sort_type}
          page={page as number}
          page_size={page_size as number}
          setDateValue={setDateValue}
          setTaxInformationId={setTaxInformationId}
          dateValue={dateValue}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          setUserId={setUserId}
          taxInformationId={taxInformationId}
          userId={userId}
          handleRetrieveData={handleRetrieveData}
        />
        <ExpensesTable
          setSortBy={setSortBy}
          setSortType={setSortType}
          sort_by={sort_by}
          sort_type={sort_type}
          data={data}
          total={total}
          page={page as number}
          page_size={page_size as number}
          setPageSize={setPageSize}
          setPage={setPage}
          handleRetrieveData={handleRetrieveData}
          isLoading={isLoading}
        />
      </Stack>
    </PageLayout>
  )
}

export { ReceiptsDashboardPage }
