import React from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList, Flex, useMediaQuery, Text } from '@chakra-ui/react'
import { Table, PaginationState } from '@tanstack/react-table'
import { IoArrowDown } from 'react-icons/io5'
import { PAGINATION_CONFIG } from './Table'
import { colors } from '../../utils/colors'

interface IPaginatorProps<Data extends object> {
  table: Table<Data>
  pagination: PaginationState
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>
  pageCount: number
  isDisabled?: boolean
  total: number
  page?: number
  page_size?: number
  setPage?: (value: number) => void
  setPageSize?: (value: number) => void
}

const getPaginationItems = (currentPage: number, totalPages: number) => {
  if (totalPages <= 7) {
    let pages: (number | string)[] = []
    // If there are 7 or fewer pages, display them all
    for (let i = 0; i < totalPages; i++) {
      pages.push(i)
    }
    return pages
  } else {
    if (currentPage <= 3) {
      // Near the start
      return [0, 1, 2, 3, 4, '...', totalPages - 1]
    } else if (currentPage <= totalPages - 1 && currentPage >= totalPages - 5) {
      return [0, '...', totalPages - 6, totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1]
    } else {
      return [0, '...', currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, '...', totalPages - 1]
    }
  }
}

export function Paginator<Data extends object>({ table, pagination, setPagination, pageCount, isDisabled, total, page, page_size, setPage, setPageSize }: IPaginatorProps<Data>) {
  const [isLargeScreen] = useMediaQuery('(min-width: 1024px)')

  const pageIndex = page && page_size ? page - 1 : pagination.pageIndex
  const pages = React.useMemo(() => getPaginationItems(pageIndex, pageCount), [pageIndex, table, page, page_size, pagination, pageCount])

  React.useEffect(() => {
    if (page !== undefined && page_size !== undefined) {
      table.setPageIndex(page - 1)
      table.setPageSize(page_size)
    }
  }, [page, page_size, table])

  return (
    <Flex
      width='fit-content'
      alignSelf='end'
      justifyContent='end'
      my='1rem'
      gap='0.7rem'
    >
      <Button
        isDisabled={isDisabled || page === 1}
        onClick={() => {
          if (setPage && setPageSize) {
            setPage(1)
          } else table.firstPage()
        }}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </Button>
      <Button
        isDisabled={isDisabled || page === 1}
        onClick={() => {
          if (setPage && setPageSize) {
            //@ts-ignore
            setPage(page - 1)
          } else table.previousPage()
        }}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </Button>
      {isLargeScreen
        ? pages.map((page_, index) => {
            if (page_ === '...') {
              return (
                <Text
                  key={`ellipsis-${index}`}
                  alignSelf='center'
                  mx='2'
                >
                  ...
                </Text>
              )
            } else {
              return (
                <Button
                  isDisabled={isDisabled}
                  key={`page-${page_}`}
                  bg={pageIndex === page_ ? colors.tertiary[3] : 'initial'}
                  onClick={() => {
                    if (setPage && setPageSize) {
                      //@ts-ignore
                      setPage(page_ + 1)
                    } else table.setPageIndex(page_ as number)
                  }}
                >
                  {(page_ as number) + 1}
                </Button>
              )
            }
          })
        : null}
      <Button
        isDisabled={isDisabled || pageCount === page}
        onClick={() => {
          if (setPage && setPageSize) {
            //@ts-ignore
            setPage(page + 1)
          } else table.nextPage()
        }}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </Button>
      <Button
        isDisabled={isDisabled || pageCount === page}
        onClick={() => {
          if (setPage && setPageSize) {
            //@ts-ignore
            setPage(Math.ceil(total / pagination.pageSize))
          } else table.lastPage()
        }}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </Button>
      <Menu>
        <MenuButton
          isDisabled={isDisabled}
          shadow='base'
          as={Button}
          rightIcon={<IoArrowDown />}
        >
          {pagination.pageSize}
        </MenuButton>
        <MenuList
          onClick={(e) => {
            //@ts-ignore
            if (!e.target.value) return
            if (setPage && setPageSize) {
              //@ts-ignore
              setPage(1)
              //@ts-ignore
              setPageSize(Number(e.target.value))
            } else {
              //@ts-ignore
              table.setPageSize(Number(e.target.value))
              table.firstPage()
            }
          }}
        >
          {PAGINATION_CONFIG.pages.map((page, index) => (
            <MenuItem
              value={page}
              key={`${page}-${index}`}
            >
              {page}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}
