import React from 'react'
import { DataTable } from '../fragments/Table'
import { routerObj } from '../routing/Router'
import { Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { FaPencilAlt, FaTrash, FaTrashRestore } from 'react-icons/fa'
import { Buffer } from 'buffer'
import { createColumnHelper, CellContext } from '@tanstack/react-table'
import { User, UserRole, UserRoleTranslator } from '../../ts/interfaces/interfaces'
import { useNavigate } from 'react-router-dom'

export interface TableData extends User {
  action?: string
}

interface IUserTableProps {
  data: User[]
  total: number
  isLoading: boolean
  handleDeleteIconButton: (data: CellContext<TableData, string | undefined>) => void
  page: number
  page_size: number
  setPage?: (value: number) => void
  setPageSize?: (value: number) => void
}

const UserTable = React.memo(({ isLoading, handleDeleteIconButton, data, page, page_size, total, setPage, setPageSize }: IUserTableProps) => {
  const columnHelper = createColumnHelper<TableData>()
  const navigate = useNavigate()

  const tableData = React.useMemo(() => {
    return [...data].sort((a, b) => ((a.name || a.email).toLowerCase() < (b.name || b.email).toLocaleLowerCase() ? 1 : -1)).sort((a, b) => (a.deleted_at && !b.deleted_at ? 1 : -1))
  }, [data])

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>,
        header: 'Nombre'
      }),
      columnHelper.accessor('email', {
        cell: (info) => (
          <Flex flexDir='column'>
            <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>
            <Text fontWeight={600}>{info.row.original.role === UserRole.ADMIN ? `(${UserRoleTranslator[info.row.original.role]})` : null}</Text>
          </Flex>
        ),
        header: 'Email'
      }),
      columnHelper.accessor('phone_numbers', {
        cell: (info) => <Text opacity={info.row.original.deleted_at ? 0.4 : 1}>{info.getValue()}</Text>,
        header: 'WhatsApp'
      }),
      columnHelper.accessor('action', {
        cell: (data) => {
          return (
            <Flex columnGap='1rem'>
              <Tooltip label='Modificar'>
                <IconButton
                  onClick={() => {
                    navigate(`${routerObj.UpdateUser.cleanParamsPath}/${Buffer.from(data.row.original.id.toString()).toString('base64')}`)
                  }}
                  aria-label='row modify user'
                  icon={<FaPencilAlt />}
                />
              </Tooltip>

              <Tooltip label={data.row.original.deleted_at ? 'Habilitar' : 'Deshabilitar'}>
                <IconButton
                  onClick={() => handleDeleteIconButton(data)}
                  aria-label='row enable-disable user'
                  icon={data.row.original.deleted_at ? <FaTrashRestore /> : <FaTrash />}
                />
              </Tooltip>
            </Flex>
          )
        },
        header: 'Acciones'
      })
    ],
    [navigate, data]
  )

  return (
    <DataTable
      total={total}
      page={page}
      page_size={page_size}
      setPage={setPage}
      setPageSize={setPageSize}
      isLoading={isLoading}
      data={tableData}
      columns={columns}
    />
  )
})

export { UserTable }
