import { Box, Flex, SimpleGrid, Stack, Text, Link as ChakraLink, Icon } from '@chakra-ui/react'
import { TranslatedProductType } from '../../ts/interfaces/interfaces'
import React from 'react'
import { useAppSelector } from '../../store/hooks'
import { MdArrowRight } from 'react-icons/md'

const UserSubscription = React.memo(() => {
  const subscription = useAppSelector((state) => state.subscription)

  const handleSubscriptionLink = React.useMemo(() => {
    if (subscription.payment?.type === 'STRIPE') return process.env.REACT_APP_SUBSCRIPTION_MANAGE_STRIPE_URL
    else if (subscription.payment?.type === 'BANK_TRANSFER') return process.env.REACT_APP_HELP_URL
  }, [subscription])

  const Feature = ({ children }: { children?: any; key: string }) => {
    return (
      <Flex align='center'>
        <Flex shrink={0}>
          <Icon
            boxSize={5}
            mt={1}
            mr={2}
            color='brand.500'
            _dark={{
              color: 'brand.300'
            }}
            viewBox='0 0 20 20'
            fill='currentColor'
            as={MdArrowRight}
          />
        </Flex>
        <Box ml={4}>
          <Text
            mt={2}
            color='gray.700'
            _dark={{
              color: 'gray.400'
            }}
          >
            {children}
          </Text>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex
      display={subscription.products.length ? 'flex' : 'none'}
      boxSize='full'
      bg='#F9FAFB'
      alignItems='center'
      justifyContent='center'
      shadow='md'
    >
      <Box
        mx='auto'
        textAlign={{
          base: 'left',
          md: 'center'
        }}
        rounded='md'
        shadow='base'
        w='full'
      >
        <Box>
          <SimpleGrid
            gap='24px'
            rounded='md'
            textAlign='left'
          >
            <Box
              bg='white'
              _dark={{
                bg: 'gray.800'
              }}
              pt={10}
              rounded='md'
            >
              <Flex direction='column'>
                <Box
                  px={10}
                  pb={5}
                >
                  <Text
                    mb={2}
                    fontSize='3xl'
                    fontWeight={['bold', 'extrabold']}
                    color='gray.900'
                    _dark={{
                      color: 'gray.50'
                    }}
                    lineHeight='tight'
                  >
                    Mi Suscripción
                  </Text>
                </Box>
                <Flex
                  px={10}
                  pt={5}
                  pb={10}
                  direction='column'
                  bg='gray.50'
                  _dark={{
                    bg: 'gray.900'
                  }}
                  roundedBottom='md'
                >
                  <Stack
                    mb={5}
                    spacing={4}
                  >
                    {subscription.products.map((product) => (
                      <Feature key={`${product.type}-${product.limit}`}>
                        {TranslatedProductType[product.type]}: {product.limit?.max}
                      </Feature>
                    ))}
                  </Stack>
                  <ChakraLink
                    href={handleSubscriptionLink}
                    target='_blank'
                    alignItems='center'
                    justifyContent='center'
                    fontWeight='semibold'
                    rounded='md'
                    mt='1rem'
                  >
                    ¿Te gustaría modificar tu suscripción actual? Contáctanos.
                  </ChakraLink>
                </Flex>
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Flex>
  )
})

export { UserSubscription }
